// instrumentation.ts
import * as Sentry from '@sentry/nextjs';

export function register() {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_VERSION,
    dist: process.env.NEXT_PUBLIC_BUILD_ID,
    environment: process.env.ENV,
    tracesSampleRate: 1, // Adjust based on your environment
    debug: false,
    ignoreTransactions: ['/api/monitoring/health'],
    beforeSend(event) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Sentry event:', event); // console.error the event instead of sending it in non-production environments
        return null; // Return null to prevent the event from being sent to Sentry
      }
      return event;
    },
  });
}
